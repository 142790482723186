<template>
  <div style="margin-top: 50px;">
    Đây là page Phân công nhân viên 

    Token:
    <pre>
    {{ access_token }}
    </pre>
  </div>
</template>

<script>
export default {
  data() {
    return {
      access_token: this.$route.query.access_token
    }
  }
};
</script>

<style lang="sass" scoped>
</style>