<template>
  <div>
    <!-- Tags -->
    <div class="tags">
      <div
        class="tag"
        :class="{ active: tag === 'information' }"
        @click="tag = 'information'"
      >
        Thông tin
      </div>
      <div
        class="tag"
        :class="{ active: tag === 'schedule' }"
        @click="tag = 'schedule'"
      >
        Lịch hẹn
      </div>
      <div
        class="tag"
        :class="{ active: tag === 'notes' }"
        @click="tag = 'notes'"
      >
        Ghi chú
      </div>
      <div
        class="tag"
        :class="{ active: tag === 'conversation' }"
        @click="tag = 'conversation'"
      >
        Thẻ hội thoại
      </div>
      <div
        class="tag"
        :class="{ active: tag === 'staff-assignment' }"
        @click="tag = 'staff-assignment'"
      >
        Phân công nhân viên
      </div>
    </div>

    <!-- Components view -->
    <Conversation v-if="tag === 'conversation'"></Conversation>
    <Information v-if="tag === 'information'"></Information>
    <Notes v-if="tag === 'notes'"></Notes>
    <Schedule v-if="tag === 'schedule'"></Schedule>
    <StaffAssignment v-if="tag === 'staff-assignment'"></StaffAssignment>
    
  </div>
</template>

<script>
import Conversation from "@/components/Conversation.vue";
import Information from "@/components/Information.vue";
import Notes from "@/components/Notes.vue";
import Schedule from "@/components/Schedule.vue";
import StaffAssignment from "@/components/StaffAssignment.vue";

export default {
  data() {
    return {
      tag: "information",
    };
  },
  components: {
    Conversation,
    Information,
    Notes,
    Schedule,
    StaffAssignment,
  },
};
</script>

<style lang="scss">
.tags {
  display: flex;
  width: 100%;
  overflow: auto;

  .tag {
    white-space: nowrap;
    margin-right: 15px;
    padding: 0px 5px 5px 5px;
    font-size: 16px;
    font-weight: 500;
  }

  .active {
    border-bottom: 3px solid #ff5f0b;
  }
}
</style>
