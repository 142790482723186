<template>
  <div style="margin-top: 50px;">
    Đây là page lịch hẹn
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
</style>